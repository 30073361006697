import React, { createContext, useContext, useState } from 'react';

const GlobalStateContext = createContext();
const GlobalStateUpdateContext = createContext();

export const useGlobalState = () => useContext(GlobalStateContext);
export const useGlobalStateUpdate = () => useContext(GlobalStateUpdateContext);

export const GlobalStateProvider = ({ children }) => {
  const [showStudio, setShowStudio] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const toggleStudio = () => {
    setShowStudio(prev => {
      if (!prev) setShowContact(false); // Close Contact if Studio is opening
      return !prev;
    });
  };

  const toggleContact = () => {
    setShowContact(prev => {
      if (!prev) setShowStudio(false); // Close Studio if Contact is opening
      return !prev;
    });
  };

  return (
    <GlobalStateContext.Provider value={{ showStudio, showContact }}>
      <GlobalStateUpdateContext.Provider value={{ toggleStudio, toggleContact }}>
        {children}
      </GlobalStateUpdateContext.Provider>
    </GlobalStateContext.Provider>
  );
};
