import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../Modals/LoadingPage';
import useIsMobile from "../../Hooks/useIsMobile";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import '../styles/projects.css';

const monolythe = require('../../Videos/monolythe-v2.webm')


const Projects = ({ onBlackoutChange}) => {
  const [projects, setProjects] = useState([]);
  const [fadeOut, setFadeOut] = useState(false);
  const [showIntroVideo, setShowIntroVideo] = useState(false);
  const [introVideoFadeOut, setIntroVideoFadeOut] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(1);
  const [loadingPageComplete, setLoadingPageComplete] = useState(false);
  const [introVideo, setIntroVideo] = useState('')
  const [visible, setVisible] = useState(true);
  const [hasSeenIntro, setHasSeenIntro] = useState(false);
  const projectListRef = useRef(null);
  const projectListMirrorRef = useRef(null);
  const normalVideoRefs = useRef([]);
  const mirrorVideoRefs = useRef([]);
  const fixedVideoRef = useRef(null);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

// fetch des projets
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/projects`)
      .then(response => {
        setProjects(response.data);
        setVisible(true);
      })
      .catch(error => {
        console.error('Erreur lors du fetch des projets', error);
      });

      if (window.innerWidth <= 500) {
        setIntroVideo(require('../../Videos/intro-ladall-iphone.mp4'));
      } else if (window.innerWidth <= 1200) {
        setIntroVideo(require('../../Videos/intro-ladall-portable.mp4'));
      } else {
        setIntroVideo(require('../../Videos/intro-ladall-v4.mp4'));
      }

    const seenIntro = localStorage.getItem('hasSeenIntro');
    if (seenIntro) {
      const seenDate = new Date(seenIntro);
      const now = new Date();
      // Vérifie si la date est inférieure à une heure
      if (now - seenDate < 60 * 60 * 1000) {
        setHasSeenIntro(true);
      } else {
          // Supprime l'entrée si elle est plus ancienne qu'une heure
          localStorage.removeItem('hasSeenIntro');
      }
    }
    setTimeout(() => {
      setLoadingComplete(22)
    }, 4000);
  }, []);

  const setVideoLoaded = () => {
    console.log("video loaded");
    setLoadingComplete((prev) => {
      const updatedValue = prev + 1;
      if (updatedValue >= projects.length * 2) {
        console.log("All videos loaded!");
      }
      console.log(updatedValue);
      return updatedValue;
    });

  };

// envoie vers la page d'un projet
  const handleVideoClick = (id) => {
    onBlackoutChange(true);
    setFadeOut(true);
    if (document.querySelector('.custom-cursor')) {
      document.querySelector('.custom-cursor').classList.remove('hover');
    }
    setTimeout(() => {
      navigate(`/project`, { state: id });
    }, 500);
  };

// Gérer la loading page + la vidéo d'intro
  useEffect(() => {
    if (loadingComplete === projects.length * 2 && !hasSeenIntro ) {
      setShowIntroVideo(true);
      const introVideoTimeout = setTimeout(() => {
        setIntroVideoFadeOut(true);
        setTimeout(() => {
          setShowIntroVideo(false);
          setIntroVideoFadeOut(false); // Réinitialise pour la prochaine fois
          const now = new Date().toISOString();
          localStorage.setItem('hasSeenIntro', now);
        }, 1000); // Durée de l'animation de fondu en millisecondes
      }, 4700); // Durée de la vidéo d'introduction en millisecondes

      return () => clearTimeout(introVideoTimeout);
    }
  }, [loadingComplete, hasSeenIntro]);

  const handleIntroVideoEnded = () => {
    setIntroVideoFadeOut(true);
    setTimeout(() => {
      setShowIntroVideo(false);
      setIntroVideoFadeOut(false); // Réinitialise pour la prochaine fois
    }, 1000); // Durée de l'animation de fondu en millisecondes
  };


// synchronise le scroll des deux lists pour donner un effet miroir
  const handleListSynchro = () => {
    if (projectListRef.current && projectListMirrorRef.current) {
      const scrollTop = projectListRef.current.scrollTop;
      const maxScrollTop = projectListRef.current.scrollHeight - projectListRef.current.clientHeight;
      const mirrorScrollTop = (projectListMirrorRef.current.scrollHeight - projectListMirrorRef.current.clientHeight) * (1 - (scrollTop / maxScrollTop));
      projectListMirrorRef.current.scrollTop = mirrorScrollTop;
    }
  };

  useEffect(() => {
    if (projectListRef.current) {
      projectListRef.current.addEventListener('scroll', handleListSynchro);
    }
    return () => {
      if (projectListRef.current) {
        projectListRef.current.removeEventListener('scroll', handleListSynchro);
      }
    };
  }, [projects]);

  useEffect(() => {
    handleListSynchro();
    handleVideoPlay();
    addHoverListeners();
    handleVideoEnd();
  })

// Synchroniser le timing des vidéos normales et miroirs
  const handleVideoPlay = (index) => {
    const mirrorIndex = projects.length - 1 - index;
    if (mirrorVideoRefs.current[mirrorIndex]) {
      mirrorVideoRefs.current[mirrorIndex].currentTime = normalVideoRefs.current[index].currentTime;
      mirrorVideoRefs.current[mirrorIndex].play();
    }
  };

  const handleVideoPause = (index) => {
    const mirrorIndex = projects.length - 1 - index;
    if (mirrorVideoRefs.current[mirrorIndex]) {
      mirrorVideoRefs.current[mirrorIndex].pause();
    }
  };

  useEffect(() => {
    const normalVideos = normalVideoRefs.current;
    const mirrorVideos = mirrorVideoRefs.current;

    normalVideos.forEach((video, index) => {
      if (video) {
        video.addEventListener('play', () => handleVideoPlay(index));
        video.addEventListener('pause', () => handleVideoPause(index));
        video.addEventListener('seeked', () => {
          const mirrorIndex = projects.length - 1 - index;
          if (mirrorVideos[mirrorIndex]) {
            mirrorVideos[mirrorIndex].currentTime = video.currentTime;
          }
        });
      }
    });

    return () => {
      normalVideos.forEach((video, index) => {
        if (video) {
          video.removeEventListener('play', () => handleVideoPlay(index));
          video.removeEventListener('pause', () => handleVideoPause(index));
          video.removeEventListener('seeked', () => {
            const mirrorIndex = projects.length - 1 - index;
            if (mirrorVideos[mirrorIndex]) {
              mirrorVideos[mirrorIndex].currentTime = video.currentTime;
            }
          });
        }
      });
    };
  }, [projects]);

  const addHoverListeners = () => {
    const interactiveElements = document.querySelectorAll('.video-container');
    interactiveElements.forEach((element) => {
      if (!element.classList.contains('video')) {
        element.addEventListener('mouseenter', () => {
          const customCursor = document.querySelector('.custom-cursor');
          if (customCursor) {
            customCursor.classList.add('hover');
          }
        });
        element.addEventListener('mouseleave', () => {
          const customCursor = document.querySelector('.custom-cursor');
          if (customCursor) {
            customCursor.classList.remove('hover');
          }
        });
      }
    });
  };

  // gérer la vidéo contenant le monolythe en background
  const handleVideoEnd = () => {
    // Délai de 5 secondes avant de redémarrer la vidéo
    if (fixedVideoRef.current) {
      fixedVideoRef.current.pause();
    }
    setTimeout(() => {
      if (fixedVideoRef.current) {
        fixedVideoRef.current.play();
      }
    }, 5000);
  };

  return (
    <>
      {loadingComplete < projects.length * 2 && <LoadingPage />}
      {showIntroVideo && (
        <div className={`intro-video-container ${introVideoFadeOut ? 'fade-out' : ''}`}>
          <video className="intro-video" src={introVideo} autoPlay muted onEnded={handleIntroVideoEnded}></video>
        </div>
      )}
      {visible && (
        <>
          <div className={`fullpage`} style={isMobile ? { backgroundColor: "black" } : {}}>
            <ul className="project-list" ref={projectListRef}>
              {projects.map((project, index) => (
                project.videoSlider && (
                  <li key={project._id} className="project-item">
                    <div className="video-container" onClick={() => handleVideoClick(project._id)}>
                      <div className="video-overlay"></div>
                      <video className='background-video' src={project.videoSlider} autoPlay loop muted onLoadedData={() => { setVideoLoaded() }} ref={el => normalVideoRefs.current[index] = el}></video>
                      <div className="video-title">{project.title}</div>
                    </div>
                  </li>
                )
              ))}
            </ul>
            <div className="project-list-mirror-container">
              <ul className="project-list-mirror" ref={projectListMirrorRef}>
                {projects.slice().reverse().map((project, index) => (
                  project.videoSlider && (
                    <li key={`${project._id}-mirror`} className="project-item-mirror">
                      <div className="video-container-mirror">
                        <video className='background-video-mirror' src={project.videoSlider} autoPlay loop muted onLoadedData={() => { setVideoLoaded() }} ref={el => mirrorVideoRefs.current[index] = el}></video>
                        <div className="video-title-mirror">{project.title.split('').reverse().join('')}</div>
                      </div>
                    </li>
                  )
                ))}
              </ul>
            </div>
            <p className='ask-scroll'><FontAwesomeIcon icon={faAnglesDown}/> Scroll pour plus de projet <FontAwesomeIcon icon={faAnglesDown} /></p>
          </div>
          {!isMobile &&
           <video className="fixed-video" src={monolythe} ref={fixedVideoRef} onEnded={handleVideoEnd} muted />}
        </>
      )}
    </>
  );
};

export default Projects;
