import { useEffect, useState } from "react";
import axios from 'axios';
import { Link, useLocation, useNavigate } from "react-router-dom";
import useIsMobile from "../../Hooks/useIsMobile";
import '../styles/templates/singleProjectTemplate1.css'
import '../styles/templates/singleProjectTemplate2.css'
import '../styles/templates/singleProjectTemplate3.css'
import '../styles/templates/singleProjectTemplate4.css'
import '../styles/templates/singleProjectTemplate5.css'

const SingleProject = ({ onBlackoutChange }) => {
  const [project, setProject] = useState(null);
  const [allProjects, setAllProjects] = useState([]);
  const [animateOut, setAnimateOut] = useState(false);
  const [visible, setVisible] = useState(true);
  const isMobile = useIsMobile();
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.state;

  useEffect(() => {
    const addHoverListeners = () => {
      const interactiveElements = document.querySelectorAll('.project-agence');
      interactiveElements.forEach((element) => {
        element.addEventListener('mouseenter', () => {
          if (!element.classList.contains('video')) {
            const customCursor = document.querySelector('.custom-cursor');
            if (customCursor) {
              customCursor.classList.add('hover');
            }
          }
        });
        element.addEventListener('mouseleave', () => {
          if (!element.classList.contains('video')) {
            const customCursor = document.querySelector('.custom-cursor');
            if (customCursor) {
              customCursor.classList.remove('hover');
            }
          }
        });
      });
    };

    addHoverListeners();
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/projects/${id}`)
      .then((response) => {
        setProject(response.data);
        setAnimateOut(false);
        setVisible(true);
        onBlackoutChange(false);
      })
      .catch((error) => {
        console.error('Erreur lors du fetch du projet', error);
      })

    axios.get(`${process.env.REACT_APP_API_URL}/projects`)
    .then((response) => {
      setAllProjects(response.data);
    })
    .catch((error) => {
      console.error('Erreur lors du fetch des projets', error);
    });

  }, [id]);

  const getNextProjectId = () => {
    if (allProjects.length === 0 || !project) return null;
    const currentIndex = allProjects.findIndex(p => p._id === project._id);
    if (currentIndex === -1) return null;
    const nextIndex = (currentIndex + 1) % allProjects.length;
    return allProjects[nextIndex]._id;
  };

  const getPreviousProjectId = () => {
    if (allProjects.length === 0 || !project) return null;
    const currentIndex = allProjects.findIndex(p => p._id === project._id);
    if (currentIndex === -1) return null;
    const prevIndex = (currentIndex - 1 + allProjects.length) % allProjects.length;
    return allProjects[prevIndex]._id;
  };

  const handlePreviousProjectClick = () => {
    const prevProjectId = getPreviousProjectId();
    if (prevProjectId) {
      setAnimateOut(true);
      setTimeout(() => {
        setVisible(false);
        navigate(`/project`, { state: prevProjectId });
      }, 600);
    }
  };

  const handleNextProjectClick = () => {
    const nextProjectId = getNextProjectId();
    if (nextProjectId) {
      setAnimateOut(true);
      setTimeout(() => {
        setVisible(false);
        navigate(`/project`, { state: nextProjectId });
      }, 600);
    }
  };

  if (!project) {
    return <div
      style={{
        color: 'white',
        textAlign: "center",
        justifyContent: "center"
      }}
      ></div>;
  }

  const renderTemplate = () => {
    switch (project.template) {
      case 1:
        return (
          <>
            {(project.file1.url !== '' || project.file2.url !== '') && (
              <div className='media video'>
                {project.file1.type === "video" ? project.file1.url && <video src={project.file1.url} className="video" autoPlay muted loop /> : project.file1.url && <div className="template1-image-1" style={{ backgroundImage: `url(${project.file1.url})`}}></div>}
                {project.file2.url !== '' && (
                  project.file2.type === "video" ? project.file2.url && <video src={project.file2.url} className="video" autoPlay muted loop /> : project.file2.url && <div className="template1-image-2" style={{ backgroundImage: `url(${project.file2.url})`}}></div>
                )}
              </div>
            )}
            {project.file3.url !== '' && (
              <div className='second-media video'>
                {project.file3.type === "video" ? project.file3.url && <video src={project.file3.url} className="video" autoPlay muted loop /> : project.file3.url && <div className="template1-image-3" style={{ backgroundImage: `url(${project.file3.url})`}}></div>}
              </div>
            )}
            {project.file4.url !== '' && (
              <div className='third-media video'>
                {project.file4.type === "video" ? project.file4.url && <video src={project.file4.url} className="video" autoPlay muted loop /> : project.file4.url && <div className="template1-image-4" style={{ backgroundImage: `url(${project.file4.url})`}}></div>}
              </div>
            )}
            {(project.file5.url !== '' || project.file6.url !== '') && (
              <div className='fourth-media video'>
                {project.file5.type === "video" ? project.file5.url && <video src={project.file5.url} className="video" autoPlay muted loop /> : project.file5.url && <div className="template1-image-5" style={{ backgroundImage: `url(${project.file5.url})`}}></div>}
                {project.file6.url !== '' && (
                  project.file6.type === "video" ? project.file6.url && <video src={project.file6.url} className="video" autoPlay muted loop /> : project.file6.url && <div className="template1-image-6" style={{ backgroundImage: `url(${project.file6.url})`}}></div>
                )}
              </div>
            )}
          </>
        );
      case 2:
        return (
          <>
            {project.file1.url !== '' && (
              <div className='template2-media video'>
                {project.file1.type === "video" ? project.file1.url && <video src={project.file1.url} className="video" autoPlay muted loop /> : project.file1.url && <div className="template2-image-1" style={{ backgroundImage: `url(${project.file1.url})`}}></div>}
              </div>
            )}
            {project.file2.url !== '' && (
              <div className='template2-second-media video'>
                {project.file2.type === "video" ? project.file2.url && <video src={project.file2.url} className="video" autoPlay muted loop /> : project.file2.url && <div className="template2-image-2" style={{ backgroundImage: `url(${project.file2.url})`}}></div>}
              </div>
            )}
            {project.file3.url !== '' && (
              <div className={project.file3.type === "video" ? 'template2-third-media video' : 'template2-third-media-image video'}>
                {project.file3.type === "video" ? project.file3.url && <video src={project.file3.url} className="video" autoPlay muted loop /> : project.file3.url && <div className="template2-image-3" style={{ backgroundImage: `url(${project.file3.url})`}}></div>}
              </div>
            )}
            {(project.file4.url !== '' || project.file5.url !== '') && (
              <div className='template2-fourth-media video'>
                {project.file4.type === "video" ? project.file4.url && <video src={project.file4.url} className="video" autoPlay muted loop /> : project.file4.url && <div className="template2-image-4" style={{ backgroundImage: `url(${project.file4.url})`}}></div>}
                {project.file5.url !== '' && (
                  project.file5.type === "video" ? project.file5.url && <video src={project.file5.url} className="video" autoPlay muted loop /> : project.file5.url && <div className="template2-image-5" style={{ backgroundImage: `url(${project.file5.url})`}}></div>
                )}
              </div>
            )}
          </>
        );
      case 3:
        return (
          <>
            {project.file1.url !== '' && (
              <div className={project.file1.type === "video" ? 'template3-media video' : 'template3-media-image video'}>
                {project.file1.type === "video" ? project.file1.url && <video src={project.file1.url} className="video" autoPlay muted loop /> : project.file1.url && <div className="template3-image-1" style={{ backgroundImage: `url(${project.file1.url})`}}></div>}
              </div>
            )}
            {(project.file2.url !== '' || project.file3.url !== '') && (
              <div className='template3-second-media video'>
                {project.file2.type === "video" ? project.file2.url && <video src={project.file2.url} className="video" autoPlay muted loop /> : project.file2.url && <div className="template3-image-2" style={{ backgroundImage: `url(${project.file2.url})`}}></div>}
                {project.file3.url !== '' && (
                  project.file3.type === "video" ? project.file3.url && <video src={project.file3.url} className="video" autoPlay muted loop /> : project.file3.url && <div className="template3-image-3" style={{ backgroundImage: `url(${project.file3.url})`}}></div>
                )}
              </div>
            )}
            {project.file4.url !== '' && (
              <div className='template3-third-media video'>
                {project.file4.type === "video" ? project.file4.url && <video src={project.file4.url} className="video" autoPlay muted loop /> : project.file4.url && <div className="template3-image-4" style={{ backgroundImage: `url(${project.file4.url})`}}></div>}
              </div>
            )}
            {project.file5.url !== '' && (
              <div className='template3-fourth-media video'>
                {project.file5.type === "video" ? project.file5.url && <video src={project.file5.url} className="video" autoPlay muted loop /> : project.file5.url && <div className="template3-image-5" style={{ backgroundImage: `url(${project.file5.url})`}}></div>}
              </div>
            )}
          </>
        );
      case 4:
        return (
          <>
            {project.file1.url !== '' && (
              <div className='template4-media video'>
                {project.file1.type === "video" ? project.file1.url && <video src={project.file1.url} className="video" autoPlay muted loop /> : project.file1.url && <div className="template4-image-1" style={{ backgroundImage: `url(${project.file1.url})`}}></div>}
              </div>
            )}
            {(project.file2.url !== '' || project.file3.url !== '') && (
              <div className='template4-second-media video'>
                {project.file2.type === "video" ? project.file2.url && <video src={project.file2.url} className="video" autoPlay muted loop /> : project.file2.url && <div className="template4-image-2" style={{ backgroundImage: `url(${project.file2.url})`}}></div>}
                {project.file3.url !== '' && (
                  project.file3.type === "video" ? project.file3.url && <video src={project.file3.url} className="video" autoPlay muted loop /> : project.file3.url && <div className="template4-image-3" style={{ backgroundImage: `url(${project.file3.url})`}}></div>
                )}
              </div>
            )}
            {project.file4.url !== '' && (
              <div className='template4-third-media video'>
                {project.file4.type === "video" ? project.file4.url && <video src={project.file4.url} className="video" autoPlay muted loop /> : project.file4.url && <div className="template4-image-4" style={{ backgroundImage: `url(${project.file4.url})`}}></div>}
              </div>
            )}
            {project.file5.url !== '' && (
              <div className={project.file5.type === "video" ? 'template4-fourth-media video' : 'template4-fourth-media-image video'}>
                {project.file5.type === "video" ? project.file5.url && <video src={project.file5.url} className="video" autoPlay muted loop /> : project.file5.url && <div className="template4-image-5" style={{ backgroundImage: `url(${project.file5.url})`}}></div>}
              </div>
            )}
          </>
        );
      case 5:
        return (
          <>
            {project.file1.url !== '' && (
              <div className='template5-media video'>
                {project.file1.type === "video" ? project.file1.url && <video src={project.file1.url} className="video" autoPlay muted loop /> : project.file1.url && <div className="template5-image-1" style={{ backgroundImage: `url(${project.file1.url})`}}></div>}
              </div>
            )}
            {project.file2.url !== '' && (
              <div className='template5-second-media video'>
                {project.file2.type === "video" ? project.file2.url && <video src={project.file2.url} className="video" autoPlay muted loop /> : project.file2.url && <div className="template5-image-2" style={{ backgroundImage: `url(${project.file2.url})`}}></div>}
              </div>
            )}
            {(project.file3.url !== '' || project.file4.url !== '') && (
              <div className='template5-third-media video'>
                {project.file3.type === "video" ? project.file3.url && <video src={project.file3.url} className="video" autoPlay muted loop /> : project.file3.url && <div className="template5-image-3" style={{ backgroundImage: `url(${project.file3.url})`}}></div>}
                {project.file4.url !== '' && (
                  project.file4.type === "video" ? project.file4.url && <video src={project.file4.url} className="template5-third-media-video2 video" autoPlay muted loop /> : project.file4.url && <div className="template5-image-4" style={{ backgroundImage: `url(${project.file4.url})`}}></div>
                )}
              </div>
            )}
            {project.file5.url !== '' && (
              <div className='template5-fourth-media video'>
                {project.file5.type === "video" ? project.file5.url && <video src={project.file5.url} className="video" autoPlay muted loop /> : project.file5.url && <div className="template5-image-5" style={{ backgroundImage: `url(${project.file5.url})`}}></div>}
              </div>
            )}
            {project.file6.url !== '' && (
              <div className='template5-fifth-media video'>
                {project.file6.type === "video" ? project.file6.url && <video src={project.file6.url} className="video" autoPlay muted loop /> : project.file6.url && <div className="template5-image-6" style={{ backgroundImage: `url(${project.file6.url})`}}></div>}
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`single-project ${animateOut ? 'slide-out-up' : 'slide-in-down'}`} style={{ backgroundColor: "black" }}>
      {visible && (
        <>
          {!isMobile ? (
            <>
              <div className="single-project-title">
              {project.vimeoVideo && (
                <iframe
                src={`https://player.vimeo.com/video/${project.vimeoVideo}?h=b7a6c21dca&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&controls=0`}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Vimeo Video"
                />
              )}
            </div>
            <div className='descriptions'>
              <p className="project-title">{project.title}</p>
              <p className="project-description">{project.descriptions}</p>
              <p className="project-type">{project.type}</p>
              <span>Client :</span><span><Link className="project-agence">{project.agence}</Link></span>
            </div>
            {renderTemplate()}
            <div className="buttons-container mt-3">
              <div className="button-container mt-3">
                {/* <h3 className="next-project-text">Projet précédent</h3> */}
                <button className="prev-project-button mt-3" onClick={handlePreviousProjectClick}></button>
              </div>
              <div className="button-container mt-3">
                {/* <h3 className="next-project-text">Projet suivant</h3> */}
                <button className="next-project-button mt-3" onClick={handleNextProjectClick}></button>
              </div>
            </div>
          </>
          ) : (
            <>
              <div className="single-project-title">
                <iframe
                    src={`https://player.vimeo.com/video/${project.vimeoVideo}?h=b7a6c21dca&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&controls=0`}
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    title="Vimeo Video"
                  />
              </div>
              <div className='descriptions'>
                <p className="project-title">{project.title}</p>
                <p className="project-description">{project.descriptions}</p>
                <p className="project-type">{project.type}</p>
                <span>{project.client}</span><span><Link className="project-agence">{project.agence}</Link></span>
              </div>
              <div className='mobile-media'>
                {project.file1.url !== '' && (
                  project.file1.type === "video"
                    ? <video src={project.file1.url} className="video" autoPlay muted loop />
                    : <div className="mobile-template-image" style={{ backgroundImage: `url(${project.file1.url})`}}></div>
                )}
                {project.file2.url !== '' && (
                  project.file2.type === "video"
                    ? <video src={project.file2.url} className="video" autoPlay muted loop />
                    : <div className="mobile-template-image" style={{ backgroundImage: `url(${project.file2.url})`}}></div>
                )}
                {project.file3.url !== '' && (
                  project.file3.type === "video"
                    ? <video src={project.file3.url} className="video" autoPlay muted loop />
                    : <div className="mobile-template-image" style={{ backgroundImage: `url(${project.file3.url})`}}></div>
                )}
                {project.file4.url !== '' && (
                  project.file4.type === "video"
                    ? <video src={project.file4.url} className="video" autoPlay muted loop />
                    : <div className="mobile-template-image" style={{ backgroundImage: `url(${project.file4.url})`}}></div>
                )}
                {project.file5.url !== '' && (
                  project.file5.type === "video"
                    ? <video src={project.file5.url} className="video" autoPlay muted loop />
                    : <div className="mobile-template-image" style={{ backgroundImage: `url(${project.file5.url})`}}></div>
                )}
                {project.file6.url !== '' && (
                  project.file6.type === "video"
                    ? <video src={project.file6.url} className="video" autoPlay muted loop />
                    : <div className="mobile-template-image" style={{ backgroundImage: `url(${project.file6.url})`}}></div>
                )}
              </div>
              <div className="buttons-container mt-3">
                <div className="button-container mt-3">
                  {/* <h3 className="next-project-text">Projet précédent</h3> */}
                  <button className="prev-project-button mt-3" onClick={handlePreviousProjectClick}></button>
                </div>
                <div className="button-container mt-3">
                  {/* <h3 className="next-project-text">Projet suivant</h3> */}
                  <button className="next-project-button mt-3" onClick={handleNextProjectClick}></button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default SingleProject;
