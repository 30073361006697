import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/updateProject.css'

const page1 = require('../../Images/template/page01.png');
const page2 = require('../../Images/template/page02.png');
const page3 = require('../../Images/template/page03.png');
const page4 = require('../../Images/template/page04.png');
const page5 = require('../../Images/template/page05.png');


const UpdateProject = () => {
  const location = useLocation();
  const navigate= useNavigate();
  const projectId = location.state;

  const [title, setTitle] = useState('');
  const [descriptions, setDescriptions] = useState('');
  const [type, setType] = useState('');
  const [vimeoVideo, setVimeoVideo] = useState('');
  const [agence, setAgence] = useState('');
  const [template, setTemplate] = useState(1);
  const [videoSlider, setVideoSlider] = useState(null);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const [file5, setFile5] = useState(null);
  const [file6, setFile6] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/projects/${projectId}`);
        const project = response.data;
        setTitle(project.title);
        setDescriptions(project.descriptions);
        setType(project.type || '');
        setAgence(project.agence || '');
        setVideoSlider(project.videoSlider || null);
        setFile1(project.file1?.url ? { url: project.file1.url } : null);
        setFile2(project.file2?.url ? { url: project.file2.url } : null);
        setFile3(project.file3?.url ? { url: project.file3.url } : null);
        setFile4(project.file4?.url ? { url: project.file4.url } : null);
        setFile5(project.file5?.url ? { url: project.file5.url } : null);
        setFile6(project.file6?.url ? { url: project.file6.url } : null);
        setTemplate(project.template !== undefined ? project.template : 1);
        setVimeoVideo(project.vimeoVideo || '')
      } catch (error) {
        console.error('Erreur lors de la récupération du projet:', error);
      }
    };

    if (projectId) {
      fetchProject();
    }
  }, [projectId]);


  const handleFileChange = (setter) => (e) => {
    setter(e.target.files[0]);
  };

  const handleTemplateChange = (e) => {
    setTemplate(Number(e.target.value));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    setMessage("Modification du projet en cours...")

    const formData = new FormData();

    formData.append('title', title);
    formData.append('descriptions', descriptions);
    formData.append('type', type);
    formData.append('vimeoVideo', vimeoVideo);
    formData.append('agence', agence);
    formData.append('template', template);

    if (videoSlider) formData.append('videoSlider', videoSlider);
    if (file1) formData.append('file1', file1);
    if (file2) formData.append('file2', file2);
    if (file3) formData.append('file3', file3);
    if (file4) formData.append('file4', file4);
    if (file5) formData.append('file5', file5);
    if (file6) formData.append('file6', file6);

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/projects/${projectId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Project updated successfully:', response.data);
      setMessage('Projet modifié avec succès !')
    } catch (error) {
      console.error('Failed to update project:', error);
      setMessage('Erreur lors de la modification du projet...');
    }
  };

  const getFileClass = (file) => (file ? 'filled' : '');

  return (
    <div className="update-project-container">
      <form className="update-project-form" onSubmit={handleUpdate}>
        <div className="form-group">
          <label htmlFor="title">Titre:</label>
          <input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="type">Type:</label>
          <input type="text" id="type" value={type} onChange={(e) => setType(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="agence">Agence:</label>
          <input type="text" id="agence" value={agence} onChange={(e) => setAgence(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="descriptions">Descriptions:</label>
          <textarea type="text" id="descriptions" value={descriptions} onChange={(e) => setDescriptions(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="videoSlider">Vidéo Slider:</label>
          <input
            type="file"
            id="videoSlider"
            name="videoSlider"
            onChange={handleFileChange(setVideoSlider)}
            accept="video/*"
          />
        </div>
        <div className="form-group">
          <label htmlFor="vimeoVideo">Numéro de vidéo Vimeo:</label>
          <input
            type="text"
            id="vimeoVideo"
            value={vimeoVideo}
            onChange={(e) => setVimeoVideo(e.target.value)}
            placeholder="Numéro de la vidéo Vimeo"
          />
        </div>
        <div className="form-group">
          <label>Template :</label>
          <div className="template-selection">
            <div className="template-item">
              <img src={page1} alt="Template 1" className="template-preview" />
              <input
                type="radio"
                className="form-check-input"
                id="template1"
                name="template"
                value="1"
                checked={template === 1}
                onChange={handleTemplateChange}
              />
              <label htmlFor="template1" className="form-check-label">Template 1</label>
            </div>
            <div className="template-item">
              <img src={page2} alt="Template 2" className="template-preview" />
              <input
                type="radio"
                className="form-check-input"
                id="template2"
                name="template"
                value="2"
                checked={template === 2}
                onChange={handleTemplateChange}
              />
              <label htmlFor="template2" className="form-check-label">Template 2</label>
            </div>
            <div className="template-item">
              <img src={page3} alt="Template 3" className="template-preview" />
              <input
                type="radio"
                className="form-check-input"
                id="template3"
                name="template"
                value="3"
                checked={template === 3}
                onChange={handleTemplateChange}
              />
              <label htmlFor="template3" className="form-check-label">Template 3</label>
            </div>
            <div className="template-item">
              <img src={page4} alt="Template 4" className="template-preview" />
              <input
                type="radio"
                className="form-check-input"
                id="template4"
                name="template"
                value="4"
                checked={template === 4}
                onChange={handleTemplateChange}
              />
              <label htmlFor="template4" className="form-check-label">Template 4</label>
            </div>
            <div className="template-item">
              <img src={page5} alt="Template 5" className="template-preview" />
              <input
                type="radio"
                className="form-check-input"
                id="template5"
                name="template"
                value="5"
                checked={template === 5}
                onChange={handleTemplateChange}
              />
              <label htmlFor="template5" className="form-check-label">Template 5</label>
            </div>
          </div>
        </div>
        <div className="file-container">
          <div className="form-group">
            <label htmlFor="file1">Fichier 1:</label>
            <input type="file" id="file1" className={getFileClass(file1)} accept="image/*,video/*" onChange={e => handleFileChange(setFile1)(e)} />
          </div>
          <div className="form-group">
            <label htmlFor="file2">Fichier 2:</label>
            <input type="file" id="file2" className={getFileClass(file2)}  accept="image/*,video/*" onChange={e => handleFileChange(setFile2)(e)} />
          </div>
          <div className="form-group">
            <label htmlFor="file3">Fichier 3:</label>
            <input type="file" id="file3" className={getFileClass(file3)} accept="image/*,video/*" onChange={e => handleFileChange(setFile3)(e)} />
          </div>
          <div className="form-group">
            <label htmlFor="file4">Fichier 4:</label>
            <input type="file" id="file4" className={getFileClass(file4)} accept="image/*,video/*" onChange={e => handleFileChange(setFile4)(e)} />
          </div>
          <div className="form-group">
            <label htmlFor="file5">Fichier 5:</label>
            <input type="file" id="file5" className={getFileClass(file5)} accept="image/*,video/*" onChange={e => handleFileChange(setFile5)(e)} />
          </div>
          <div className="form-group">
            <label htmlFor="file6">Fichier 6:</label>
            <input type="file" id="file6" className={getFileClass(file6)} accept="image/*,video/*" onChange={e => handleFileChange(setFile6)(e)} />
          </div>
        </div>
        <button type="submit">Mettre à jour le projet</button>
        <span className="message">{message}</span>
        <button type="button" style={{ backgroundColor: "grey"}} onClick={() => navigate(-1)}>Retour</button>
      </form>
    </div>
  );
};

export default UpdateProject;
