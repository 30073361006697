import { useEffect } from 'react';
import cursorImage from '../Images/mouse_ladall-etat01.png';
import useIsMobile from '../Hooks/useIsMobile';
import './styles/customeCursor.css';

const CustomCursor = () => {

  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) return;
    const cursor = document.createElement('div');
    cursor.className = 'custom-cursor';

    const img = new Image();
    img.src = cursorImage;
    cursor.appendChild(img);

    document.body.appendChild(cursor);

    // Gérer le suivi du curseur personnalisé
    const handleMouseMove = (e) => {
      cursor.style.left = `${e.clientX - 1}px`;
      cursor.style.top = `${e.clientY - 27}px`;
    };

    const updateCursor = (e) => {
      requestAnimationFrame(() => handleMouseMove(e));
    };

    document.addEventListener('mousemove', updateCursor);

    const handleMouseEnter = () => {
      if (cursor) {
        cursor.classList.add('hover');
      }
    };

    const handleMouseLeave = () => {
      if (cursor) {
        cursor.classList.remove('hover');
      };
    };

    const addHoverListeners = () => {
      const interactiveElements = document.querySelectorAll('a, button, [role="button"], input, select, textarea, .logo');
      interactiveElements.forEach((element) => {
        if (!element.classList.contains('video')) {
          element.addEventListener('mouseenter', handleMouseEnter);
          element.addEventListener('mouseleave', handleMouseLeave);
        }
      });
    };

    // Ajouter des écouteurs d'événements pour les éléments interactifs au chargement initial
    addHoverListeners();

    return () => {
      document.removeEventListener('mousemove', updateCursor);
      const interactiveElements = document.querySelectorAll('a, button, [role="button"], input, select, textarea, .logo');
      interactiveElements.forEach((element) => {
        if (document.body.contains(element)) {
          element.removeEventListener('mouseenter', handleMouseEnter);
          element.removeEventListener('mouseleave', handleMouseLeave);
        }
      });
      if (document.body.contains(cursor)) {
        document.body.removeChild(cursor);
      }
    };
}, [isMobile]);

  return null;
};

export default CustomCursor;
