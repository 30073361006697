// MobileMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/mobileMenu.css'; // Assure-toi d'ajouter les styles nécessaires

const MobileMenu = ({ isOpen, onClose, openStudio, openContact }) => {
  if (!isOpen) return null;


  return (
    <div className="mobile-menu">
      <button className="close-menu" onClick={onClose}>Fermer</button>
      <ul>
        <li><Link onClick={() => { openStudio(); onClose(); }}>Studio</Link></li>
        <li><Link onClick={() => { openContact(); onClose(); }}>Contact</Link></li>
        <li><Link onClick={onClose}>LinkedIn</Link></li>
        <li><Link onClick={onClose}>Instagram</Link></li>
        <li><Link onClick={onClose}>Behance</Link></li>
        <li><Link onClick={onClose}>Vimeo</Link></li>
      </ul>
    </div>
  );
};

export default MobileMenu;
