import { useRef, useEffect, useState } from 'react';
import { ReactComponent as Logo } from "../logo/anim-logo.svg";
import useIsMobile from '../Hooks/useIsMobile';

const AnimatedLogo = () => {
  const logoRef = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const svgElement = logoRef.current;
    const animations = svgElement.querySelectorAll('animate, animateMotion, animateTransform');

    const startAnimation = () => {
      if (!isDisabled) {
        animations.forEach((anim) => anim.beginElement());
        setIsDisabled(true);

        setTimeout(() => {
          setIsDisabled(false);
        }, 1440); // Désactiver pendant 1,44 secondes (la durée d'animation du logo)
      }
    };

    svgElement.addEventListener('mouseover', startAnimation);


    return () => {
      svgElement.removeEventListener('mouseover', startAnimation);

    };
  }, [isDisabled]);

  useEffect(() => {
    const svgElement = logoRef.current;
    const animations = svgElement.querySelectorAll('animate, animateMotion, animateTransform');
    setTimeout(() => {
      animations.forEach((anim) => anim.beginElement());
    }, 200);
  }, [])

  return <Logo ref={logoRef} width={isMobile ? "15" : "120"} height={isMobile ? "30" : "160"}/>;
};

export default AnimatedLogo;
