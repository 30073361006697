// eslint-disable-next-line no-unused-vars
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Projects from './Component/Project/Projects';
import CreateProject from './Component/Form/CreateProject';
import UpdateProject from './Component/Form/UpdateProject';
import SingleProject from './Component/Project/SingleProject';
import Layout from './Component/Modals/Layout';
import CustomCursor from './Component/CustomCursor';
import useIsMobile from './Hooks/useIsMobile';
import { GlobalStateProvider } from './GlobalStateContext';
import './App.css';

function App() {

  const [blackoutVisible, setBlackoutVisible] = useState(false);

  const handleBlackout = (visible) => {
    setBlackoutVisible(visible);
  };
  const isMobile = useIsMobile();
  return (
    <>
      <GlobalStateProvider>
      <div className={`blackout ${blackoutVisible ? 'visible' : ''}`}></div>
        <Routes>
          <Route path="/" element={<Layout onBlackoutChange={handleBlackout}/>}>
            <Route index element={<Projects onBlackoutChange={handleBlackout} />} />
            <Route path='/project' element={<SingleProject  onBlackoutChange={handleBlackout}/>} />
            <Route path='/create-project' element={<CreateProject/>} />
            <Route path='/update-project' element={<UpdateProject/>} />
          </Route>
        </Routes>
        {!isMobile && <CustomCursor />}
      </GlobalStateProvider>
    </>
  );
}

export default App;
