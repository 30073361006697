import { useEffect} from 'react';
import { useGlobalStateUpdate } from '../../GlobalStateContext';
import { useNavigate, Link } from 'react-router-dom';
import useIsMobile from '../../Hooks/useIsMobile';
import vimeoLogo from '../../logo/vimeo-2171.svg';
import behanceLogo from '../../logo/behance-2161.svg';
import instagramLogo from '../../logo/instagram-2165.svg';
import linkedinLogo from '../../logo/linkedin-2166.svg';
import '../styles/contact.css';

const Contact = ({ isClosing }) => {

  const { toggleContact } = useGlobalStateUpdate();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isClosing) {
      const timer = setTimeout(() => {
        toggleContact();
      }, 450);
      return () => clearTimeout(timer);
    }
  }, [isClosing, toggleContact]);

  useEffect(() => {
    addHoverListeners();
  });

  const addHoverListeners = () => {
    const interactiveElements = document.querySelectorAll('button, [role="button"], input, select, textarea, .logo-contact, .logo-contact-svg, .email-link, .phone-link');
    interactiveElements.forEach((element) => {
      element.addEventListener('mouseenter', () => {
        if (!element.classList.contains('video')) {
          const customCursor = document.querySelector('.custom-cursor');
          if (customCursor) {
            customCursor.classList.add('hover');
          }
        }
      });
      element.addEventListener('mouseleave', () => {
        if (!element.classList.contains('video')) {
          const customCursor = document.querySelector('.custom-cursor');
          if (customCursor) {
            customCursor.classList.remove('hover');
          }
        }
      });
    });
  };


  return (
    <>
      <div className={`contact-overlay ${isClosing ? 'closing' : ''}`}>
          {!isMobile ? (
            <>
            <div className='contact-content-info'>
              <h4 className='catch-phrase'>Une petite faim ? C'est toujours par la dall que commence un bon repas.</h4>
              <div className='email-numero'>
                <h4>Contactez nous</h4>
                <a href="mailto:contact@ladall.com" style={{ color: 'black'}} className="email-link">
                  <h2>contact@ladall.com</h2>
                </a>
                <a href="tel:+33664635433" style={{ color: 'black'}} className="phone-link">
                  <h2>0664635433</h2>
                </a>
              </div>
              <div className='adress-logo'>
                <div className='adress'>
                  <p>LA DALL NANTES</p>
                  <p>11 avenue de l'hotel Dieu</p>
                  <p>44000 Nantes</p>
                </div>
                <div className='logos'>
                  <Link to=''><img src={linkedinLogo} className='logo-contact-svg' alt="Logo linkedin" /></Link>
                  <Link to=''><img src={instagramLogo} className='logo-contact-svg'alt="Logo instagram" /></Link>
                  <Link to=''><img src={behanceLogo} className='logo-contact-svg' alt="Logo behance" /></Link>
                  <Link to=''><img src={vimeoLogo} className='logo-contact-svg' alt="Logo vimeo"/></Link>
                </div>
                <div className='adress'>
                  <p>LA DALL LYON</p>
                  <p>Gratte-ciel</p>
                  <p>69100 Villeurbanne</p>
                </div>
              </div>
              </div>
            </>
          ) : (
            <>
            <div className='logo-contact' onClick={() => toggleContact()}></div>
            <div className='contact-content-info'>
              <div className='catch-phrase'>
                <h4>Une petite faim ?</h4>
                <h4 >C'est toujours par la dall que commence un bon repas.</h4>
                <div className='email-numero'>
                  <h5>Contactez nous</h5>
                  <a href="mailto:contact@ladall.com" style={{ color: 'black'}} className="email-link">
                    <h3>contact@ladall.com</h3>
                  </a>
                  <a href="tel:+33664635433" style={{ color: 'black'}} className="phone-link">
                    <h3>0664635433</h3>
                  </a>
              </div>
              </div>
              <div className='mobile-contact-image'></div>
              <div className='adress-logo'>
                <div className='adress'>
                  <p>LA DALL NANTES</p>
                  <p>11 avenue de l'hotel Dieu</p>
                  <p>44000 Nantes</p>
                </div>
                <div className='adress'>
                  <p>LA DALL LYON</p>
                  <p>Gratte-ciel</p>
                  <p>69100 Villeurbanne</p>
                </div>
              </div>
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default Contact;
