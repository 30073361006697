import React, { useEffect, useState } from 'react';
import AnimatedLogoHTML from '../../Videos/AnimatedLogo';
import '../styles/loadingPage.css';

const LoadingPage = () => {

  return (
    <div className="loading-page">
      <h5><AnimatedLogoHTML /></h5>
      <small>Direction & Creation Production</small>
    </div>
  );
};

export default LoadingPage;
