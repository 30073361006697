import { useRef } from 'react';
import useIsMobile from '../Hooks/useIsMobile';


const AnimatedLogoHTML = () => {
  const isMobile = useIsMobile();
  const iframeRef = useRef(null);

  const handleMouseOver = () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage('play', '*');
    }
  };

  const handleMouseOut = () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage('pause', '*');
    }
  };

  return (
      <div className="animated-logo-container" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <iframe
          src="/logo_ladall.html"
          title="Animated Logo"
          width={isMobile ? "50" : "100"}
          height={isMobile ? "60" : "150"}
        ></iframe>
      </div>
  );
};

export default AnimatedLogoHTML;
